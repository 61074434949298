import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './styleItems.scss'

class NavMainMobileItems extends Component {
    render() {
        return (
            <div className="nav-main-mobile-items">
                <div className="row">
                    <div className='col col-logo'>
                        <NavLink to='/' className='logo'><img src='/amarant-logo.svg' alt='Амарант България' /></NavLink>
                    </div>
                </div>
                <div className="row row-1">

                    <div className="col">
                        <div className="item"><NavLink activeClassName="active" to='/insurances'>ЗАСТРАХОВКИ</NavLink></div>
                        <div className="item"><NavLink activeClassName="active" to='/blog'>НОВИНИ</NavLink></div>
                        <div className="item"><NavLink activeClassName="active" to='/about-us'>ЗА НАС</NavLink></div>
                    </div>
                </div>
                <div className="row row-2">
                    <div className="item"><a className='item-bttn' href='https://amarant.app.link'>Свали приложението</a></div>
                </div>
                <div className="row row-3">

                </div>
            </div>

        );
    }
}

export default NavMainMobileItems;
