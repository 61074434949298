import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { history } from "../../config/stores";
import "./styles.scss";

const MobileHeader = () => {
  const location = useLocation();

  const [open, setOpen] = useState(false);

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  return (
    <div className="mobile-container">
      <div
        className="button"
        onClick={() => {
          setOpen(true);
          document.body.style.overflow = "hidden";
        }}
      >
        <div className="menu" style={{ marginTop: window.innerWidth < 900 && window.innerWidth > 760 && "10px" }} />
      </div>
      <Link to="/" className="mobile-header-logo" style={{ marginLeft: window.innerWidth < 900 && window.innerWidth > 760 ? "35%" : "20%" }}></Link>
      {open && (
        <div className="opened-header">
          <div className="upper-opened-header">
            <div
              className="close-opened-header"
              onClick={() => {
                setOpen(false);
                document.body.style.overflow = "visible";
              }}
            >
              <div className="close" />
            </div>
            <Link to="/" style={{ color: "black", textDecoration: "none" }}>
              <div
                className="mobile-header-element"
                onClick={() => {
                  setOpen(false);
                  document.body.style.overflow = "visible";
                }}
              >
                <div className={`mobile-header-inner-element ${location.pathname === "/" && "selected"}`}>НАЧАЛО</div>
              </div>
            </Link>
            <Link to="/subscriptions" style={{ color: "black", textDecoration: "none" }}>
              <div
                className="mobile-header-element"
                onClick={() => {
                  setOpen(false);
                  document.body.style.overflow = "visible";
                }}
              >
                <div className={`mobile-header-inner-element ${location.pathname === "/subscriptions" && "selected"}`}>АБОНАМЕНТИ</div>
              </div>
            </Link>
            <Link to="/blog" style={{ color: "black", textDecoration: "none" }}>
              <div
                className="mobile-header-element"
                onClick={() => {
                  setOpen(false);
                  document.body.style.overflow = "visible";
                }}
              >
                <div className={`mobile-header-inner-element ${location.pathname === "/blog" && "selected"}`}>НОВИНИ</div>
              </div>
            </Link>
            <div
              className="button-container"
              onClick={() => {
                if (getMobileOperatingSystem() === "iOS") {
                  window.open("https://apps.apple.com/us/app/amarant-bulgaria/id1506724182");
                } else {
                  window.open("https://play.google.com/store/apps/details?id=com.amarantapp&hl");
                }
              }}
            >
              Свали приложението
            </div>
          </div>
          <div className="lower-opened-header"></div>
        </div>
      )}
    </div>
  );
};

export default MobileHeader;
