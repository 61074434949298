import React, { Component } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import biscuits from "../../files/cookies.pdf";
import license from "../../files/license.pdf";
import doc325 from "../../files/doc325.pdf";
import protocol from "../../files/protocol.pdf";
import protocolInstructions from "../../files/protocolInstructions.pdf";
import complaints from "../../files/complaints.pdf";

import personalInfo from "../../files/personalInfo.pdf";
import personalInfo2 from "../../files/personalInfo2.pdf";
import rights from "../../files/personalInfoPolicy.pdf";
import privacyWorkers from "../../files/privacyWorkers.pdf";
import privacyClients from "../../files/privacyClients.pdf";

import insuranceInfo from "../../files/insurance_info.pdf";
import faq from "../../files/FAQ.pdf";
import steps_signing from "../../files/steps_signing.pdf";
const mainDocs = [
  {
    name: "Политика за бисквитките",
    url: biscuits,
  },
  {
    name: "Лиценз",
    url: license,
  },
  {
    name: "Информация по чл.325 от КЗ",
    url: doc325,
  },
  {
    name: "Двустранен констативен протокол",
    url: protocol,
  },
  {
    name: "Указания за попълване на двустранен констативен протокол",
    url: protocolInstructions,
  },
  {
    name: "Политика за жалбите",
    url: complaints,
  },
];

const offDocs = [
  {
    name: "Политика за защита на личните данни",
    url: personalInfo,
  },
  {
    name: "Политика за съхранение и унищожаване на личните данни",
    url: personalInfo2,
  },
  {
    name: "Политика относно упражняване на правата на субектите на данни",
    url: rights,
  },
  {
    name: "Уведомление за поверителност за кандидати за работа",
    url: privacyWorkers,
  },
  {
    name: "Уведомление за поверителност за клиенти",
    url: privacyClients,
  },
];
const otherDocs = [
  {
    name: "Информация за застрахователя и продукта",
    url: insuranceInfo,
  },
  {
    name: "Указания за присъединяване към групов договор",
    url: steps_signing,
  },
  {
    name: "Често задавани въпроси",
    url: faq,
  },
];

const Documents = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="documents">
      <div className="documents-container">
        <div className="left-documents-container">
          <div className="main-documents">ОСНОВНИ ДОКУМЕНТИ</div>
          <div className="documents-list">
            {mainDocs.map((doc) => {
              return (
                <div className="doc-container" onClick={() => window.open(doc.url)}>
                  <h4 className="doc-name">{doc.name}</h4>
                  <div className="arrowTab selectedBlue"></div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="left-documents-container" style={{ margin: "0% 3%" }}>
          <div className="main-documents">GDPR ДОКУМЕНТИ</div>
          <div className="documents-list">
            {offDocs.map((doc) => {
              return (
                <div className="doc-container" onClick={() => window.open(doc.url)}>
                  <h4 className="doc-name">{doc.name}</h4>
                  <div className="arrowTab selectedBlue" onClick={() => window.open(doc.url)}></div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="left-documents-container">
          <div className="main-documents">ДРУГИ ДОКУМЕНТИ</div>
          <div className="documents-list">
            {otherDocs.map((doc) => {
              return (
                <div className="doc-container" onClick={() => window.open(doc.url)}>
                  <h4 className="doc-name">{doc.name}</h4>
                  <div className="arrowTab selectedBlue"></div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect()(Documents);
