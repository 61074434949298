import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { AppsDownload, Socials } from "../../components";
import "./styles.scss";

class Footer extends Component {
  render() {
    return (
      <div className="footer" id="footer" style={{ height: window.innerWidth < 900 ? "unset" : "31vh" }}>
        <div className="container">
          <div className="row">
            <div className="col col-1" style={{ textAlign: window.innerWidth < 900 ? "left" : "center" }}>
              <div>
                <div className="logo">
                  <NavLink to="/">
                    <img src="/amarant-logo.svg" alt="Амарант България" />
                  </NavLink>
                </div>
                <AppsDownload />
              </div>
            </div>
            <div className="col col-2">
              <div>
                <ul className="nav-footer">
                  <li>
                    <NavLink activeClassName="active" to="/subscriptions">
                      Абонаменти
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="active" to="/blog">
                      Новини
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="active" to="/documents">
                      Документи
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col col-3">
              <div>
                <h4>Свържете се с нас</h4>
                <p>
                  <span className="icon">
                    <img src="../imgs/email.png" alt="мейл" />
                  </span>
                  office@amarantcar.com
                </p>
                <p className="p-phones">
                  <span className="icon">
                    <img src="../imgs/phone.png" alt="телефон" />
                  </span>
                  <span>02/492 13 53</span>
                  {window.innerWidth < 900 ? <span>02/492 13 55</span> : <p style={{ marginLeft: "37px", marginTop: "23px" }}>02/492 13 55</p>}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(Footer);
